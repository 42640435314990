<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="hero_content" v-if="pageIsLoaded">
            <div class="data">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ content.subhero.surtitre }}
                </h2>
                <h1 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.subhero.titre"></span>
                </h1>
            </div>
            <div class="img" data-inview="revealRight" data-delay="200">
                <img :src="content.subhero.image.url" :alt="content.subhero.image.titre" />
            </div>
            <a href="#next" v-scroll-to:-120
                ><svg
                    fill="none"
                    class="arrow"
                    viewBox="0 0 13 26"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m11.6412 17.9322-4.17083 4.2832v-22.2154h-1.94081v22.2154l-4.17082-4.2832-1.35874 1.395 6.5 6.6728 6.5-6.6728z"
                    /></svg
            ></a>
        </section>

        <section class="img_txt" id="next" v-if="pageIsLoaded">
            <div class="data">
                <h3 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ globals.servicesEntry.data[0].page.titre }}
                </h3>
                <h2 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="globals.servicesEntry.data[0].page.entete1.titre"></span>
                </h2>
                <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="globals.servicesEntry.data[0].page.entete1.texte"></span>
                </p>
                <router-link
                    :to="globals.servicesEntry.data[0].page.jsonUrl"
                    class="cta"
                    data-inview="fadeInUp"
                    data-delay="500"
                >
                    <p class="regular-text">{{ $t('En savoir plus') }}</p>
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                </router-link>
            </div>
            <div class="img" data-inview="revealRight" data-delay="200">
                <img
                    :src="globals.servicesEntry.data[0].page.imageSimple1.url"
                    :alt="globals.servicesEntry.data[0].page.imageSimple1.titre"
                />
            </div>
        </section>

        <section class="img_txt dark_switch" v-if="pageIsLoaded">
            <div class="data">
                <h3 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ globals.servicesEntry.data[1].page.titre }}
                </h3>
                <h2 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="globals.servicesEntry.data[1].page.entete1.titre"></span>
                </h2>
                <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="globals.servicesEntry.data[1].page.entete1.texte"></span>
                </p>
                <router-link
                    :to="globals.servicesEntry.data[1].page.jsonUrl"
                    class="cta"
                    data-inview="fadeInUp"
                    data-delay="500"
                >
                    <p class="regular-text">{{ $t('En savoir plus') }}</p>
                    <img src="@/assets/img/arrow-right-dark.svg" alt="arrow right" />
                </router-link>
            </div>
            <div class="img" data-inview="revealRight" data-delay="200">
                <img
                    :src="globals.servicesEntry.data[1].page.imageSimple1.url"
                    :alt="globals.servicesEntry.data[1].page.imageSimple1.titre"
                />
            </div>
        </section>

        <section class="img_txt" v-if="pageIsLoaded">
            <div class="data">
                <h3 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ globals.servicesEntry.data[2].page.titre }}
                </h3>
                <h2 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="globals.servicesEntry.data[2].page.entete1.titre"></span>
                </h2>
                <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="globals.servicesEntry.data[2].page.entete1.texte"></span>
                </p>
                <router-link
                    :to="globals.servicesEntry.data[2].page.jsonUrl"
                    class="cta"
                    data-inview="fadeInUp"
                    data-delay="500"
                >
                    <p class="regular-text">{{ $t('En savoir plus') }}</p>
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                </router-link>
            </div>
            <div class="img" data-inview="revealRight" data-delay="200">
                <img
                    :src="globals.servicesEntry.data[2].page.imageSimple1.url"
                    :alt="globals.servicesEntry.data[2].page.imageSimple1.titre"
                />
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'
// import Flickity from 'flickity'

export default {
    data() {
        return {
            lightboxelement: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.lightboxelement = GLightbox
                        GLightbox()

                        setTimeout(() => {
                            // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        servicesList() {
            if (!this.globalsIsLoaded) {
                return []
            }

            return this.globals.servicesEntry.data.slice(0, 4)
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped></style>
